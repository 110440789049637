<template>
  <b-modal id="assign-task-modal"
           @show="onShow"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('assign_task') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>

    <b-form-radio-group
        class="w-100 mt-3"
        id="btn-radios-1"
        v-model="taskType"
        :options="taskTypes"
        button-variant="outline"
        name="radios-btn-default"
        buttons
    ></b-form-radio-group>

    <div class="mt-3">
      <div v-if="taskType === 'material'">
        <h3>{{ $t('program') }}</h3>
        <div class="form-group">
          <a class="btn-whole-add btn-whole-add-dashed"
             v-if="!material"
             @click="selectProgram">
          <span class="svg-wrapper mr-3">
            <PlusSvg class="svg-icon-sm"/>
          </span>
            {{ $t('add_program') }}
          </a>

          <div class="selected-material" v-if="material">
            <b-row class="align-items-center">
              <b-col>
                <div v-if="material.course" class="text-muted small">{{ material.course.title }}</div>
                <div>{{ material.title }}</div>
              </b-col>
              <b-col cols="auto">
                <a class="btn-themed btn-white py-2" @click="selectProgram">
                  {{ $t('edit') }}
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div v-if="taskType === 'course'">
        <h3>{{ $t('lightcourse') }}</h3>
        <div class="form-group">
          <a class="btn-whole-add btn-whole-add-dashed"
             v-if="!course"
             @click="selectCourse">
          <span class="svg-wrapper mr-3">
            <PlusSvg class="svg-icon-sm"/>
          </span>
            {{ $t('add_course') }}
          </a>

          <div class="selected-material" v-if="course">
            <b-row class="align-items-center">
              <b-col>
                <div>{{ course.title }}</div>
              </b-col>
              <b-col cols="auto">
                <a class="btn-themed btn-white py-2"
                   @click="selectCourse">
                  {{ $t('edit') }}
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

    </div>

    <div v-if="taskType === 'material'">
      <h3>{{ $t('way_of_passing') }}</h3>
      <SelectGroup class="default"
                   :hasNullOption="false"
                   v-model="selfStudyType"
                   :options="selfStudyTypes"/>
    </div>

    <div>
      <h3>{{ $t('add_student') }}</h3>
      <StudentSearch @selected="studentsSelected"
                     :dropdown="true"
                     :onlyactive="true"
                     :teacher="teacher.id"
                     :editable="true"
                     :passedstudents="[]"
                     :passedgroups="[]"
                     :showgroups="true"
      />
    </div>
    <SelectMaterial @selected="materialSelected"/>
    <SelectLightCourseMaterial :select-course="true"
                               @courseSelected="courseSelected"/>
    <template v-slot:modal-footer>
      <b-button class="btn-themed"
                :disabled="!valid || loading"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import PlusSvg from '@/assets/svg/plus.svg'
import CloseSvg from '@/assets/svg/close.svg'
import StudentSearch from "../../../parts/class/StudentSearch.vue"
import SelectMaterial from "../../../parts/class/SelectMaterial.vue"
import SelectLightCourseMaterial from "../../../parts/class/SelectLightCourseMaterial.vue"
import { StudentsService } from "../../../../services/api.service"
import SelectGroup from "../../../parts/general/form/SelectGroup.vue"

export default {
  name: "AssignTaskModal",
  components: {
    SelectGroup,
    SelectLightCourseMaterial,
    SelectMaterial,
    StudentSearch,
    CloseSvg,
    PlusSvg
  },
  data() {
    return {
      loading: false,
      students: [],
      group: null,
      taskType: 'material',
      selfStudyType: 'opened',
      taskTypes: [
        { text: this.$t('mediumcourse'), value: 'material' },
        { text: this.$t('lightcourse'), value: 'course' },
      ],
      selfStudyTypes: [
        { id: 'opened', title: 'opened'},
        { id: 'as_you_go', title: 'as_you_go'},
      ],
      material: null,
      course: null
    }
  },
  computed: {
    valid() {
      return true
    },
    teacher() {
      return this.$store.state.user
    }
  },
  methods: {
    onShow() {
      this.material = null
      this.course = null
      this.students = []
      this.group = null
      this.taskType = 'material'
    },
    async save() {
      try {
        this.loading = true
        const res = await StudentsService.bulkTaskAssignment({
          studentIds: this.students,
          groupId: this.group,
          taskType: this.taskType,
          materialId: this.material ? this.material.id : null,
          courseId: this.course ? this.course.id : null,
          selfStudyType: this.selfStudyType
        })
        if(res.data.data === true) {
          this.$bvModal.hide('assign-task-modal')
        }
      } catch(err) {
        try {
          this.$error(Object.values(err.response.data.errors).map(e => e[0]).join(' '))
        } catch (e) {
          this.errResponse(err)
        }
      } finally {
        this.loading = false
      }
    },
    studentsSelected(data, type = 'students') {
      if(type === 'students') {
        this.students = data.map(a => a.id)
        this.group = null
      }
      if(type === 'groups') {
        this.group = data.length > 0 ? data[0].id : null
        this.students = []
      }
    },
    selectProgram() {
      this.$bvModal.show('selectmaterialmodal')
    },
    selectCourse() {
      this.$bvModal.show('selectLightCourseMaterial')
    },
    courseSelected(course) {
      this.course = course
    },
    materialSelected(material) {
      this.material = material
    }
  }
}
</script>

<style scoped lang="scss">
#assign-task-modal {
  .selected-material {
    background: $almost-white;
    border-radius: 16px;
    padding: 18px 24px;
    font-size: 14px;
    font-weight: 600;
  }
  .edit-btn {
    width: 16px;
    height: 16px;
    fill: $gray-fill;
  }
}
</style>
