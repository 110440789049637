<template>
    <transition name="fade">
        <div class="row mr-2">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
                <div class="video-modal d-flex justify-content-center" @keyup.esc="closeVideo" tabindex="0" v-click-outside="closeVideo">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ytBRi41IUxQ" frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                    </iframe>
                    <div class="close-place pb-3" @click="closeVideo">
                        <div class="close-btn"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "VideoModal",
        data() {
            return {}
        },
        created() {
            document.onkeydown = evt => {
                evt = evt || window.event;
                if (evt.keyCode === 27) {
                    this.closeVideo()
                }
            };
        },
        components: {},
        methods: {
            closeVideo() {
                this.$store.commit('setVideoModalOpen', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .video-modal {
        width: 100%;
        position: absolute;
        z-index: 100;
    }

    .close-place {
        cursor: pointer;

        &:hover .close-btn {
            transform: rotate(-315deg);
        }

    }

    .close-btn {
        position: absolute;
        top: -35px;
        right: 0;
        width: 35px;
        height: 3px;
        margin-left: 15px;
        background: #fff;
        transform: rotate(45deg);
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:before {
            position: absolute;
            content: '';
            width: 35px;
            height: 3px;
            background: #fff;
            transform: rotate(-90deg);
        }
    }

    iframe {
        width: 100%;
        height: 450px;
        max-height: 100%;
        border-radius: 18px;
    }

    @media(max-width: 768px) {
        .video-modal {
            width: 100%;
            flex-direction: column;
            align-items: center;
            position: absolute;
            z-index: 100;
        }
    }

</style>
